import http from "@/utils/http"

export function responsePromotion(params) {
    return http({
        url: "/api/PromotionRelationship/responsePromotion",
        data: params
    })
}

export function autoEstablishRelationship(params) {
    return http({
        url: "/api/PromotionRelationship/autoEstablishRelationship",
        data: params
    })
}